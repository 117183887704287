import { Flex } from 'component-library/components/layout/Flex';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

import { WrapperLarge } from '../shared/WrapperLarge';
import { body, heading } from './content';

const ImageAndText = styled(Flex)`
  flex-basis: 62%;
`;

const Heading = styled.div`
  flex-basis: 38%;
`;

export const OurStoryLarge = () => (
  <WrapperLarge maxWidth={1569} spacing={40}>
    <Flex>
      <Heading>
        <ResponsiveHeading
          maxWidth={1230}
          variant={2}
          marginBottom={24}
          alignment="center"
        >
          {heading}
        </ResponsiveHeading>
      </Heading>
      <ImageAndText column>
        <StaticImage src="../images/our-story.webp" alt="retirable-founders" />
        {body.map((paragraph, idx) => (
          <ResponsiveBody variant={1} marginTop={32} key={idx}>
            {paragraph}
          </ResponsiveBody>
        ))}
      </ImageAndText>
    </Flex>
  </WrapperLarge>
);
